export default function () {
    const { user } = useAuth() as { user: UserResponse, loggedIn: boolean }

    /**
     * Check if the logged-in user is a SuperAdmin or granted access with all
     * permissions
     */
    function isSuperAdmin(): boolean {
        return user.isSuperAdmin || canAccess('all_permissions')
    }

    /**
     * Check if the logged-in user has access to any of the provided roles
     *
     * NOTE: User with 'all_permissions' will automatically be allowed for all
     * accesses
     *
     * ### Example
     * ```ts
     * // Basic usage:
     * canAccess(['order_create', 'order_create_with_approval'])
     *
     * // Returns `true` if any of the roles are accessible:
     * // For example: a user with only the 'order_create' permission
     * const hasOrderCreatePerm = canAccess('order_create')
     * const hasAnyOfListed = canAccess(['order_create', 'order_update'])
     *
     * console.assert(hasOrderCreatePerm && hasAnyofListed)
     * ```
     */
    function canAccess(roleKeys: UserRoleKey | UserRoleKey[]): boolean {
        const userRoles = user.roles

        if (userRoles['all_permissions'] == 1) return true

        return Array.isArray(roleKeys)
            ? roleKeys.some(role => userRoles[role] == 1)
            : userRoles[roleKeys] == 1
    }

    return {
        isSuperAdmin,
        canAccess,
    }
}
